/*

Dark style from softwaremaniacs.org (c) Ivan Sagalaev <Maniac@SoftwareManiacs.Org>

*/

.hljs {
	display: block;
	overflow-x: auto;
	padding: 0.5em;
	background: #444;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-section,
.hljs-link {
	color: white;
}

.hljs,
.hljs-subst {
	color: #ddd;
}

.hljs-string,
.hljs-title,
.hljs-name,
.hljs-type,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-built_in,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
	color: #d88;
}

.hljs-comment,
.hljs-quote,
.hljs-deletion,
.hljs-meta {
	color: #777;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-title,
.hljs-section,
.hljs-doctag,
.hljs-type,
.hljs-name,
.hljs-strong {
	font-weight: bold;
}

.hljs-emphasis {
	font-style: italic;
}
