body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.blockquote-warning {
	margin: 24px 0;
	padding: 4px 24px;
	border-left: 5px solid #ffe564;
	background-color: rgba(255, 229, 100, 0.2);
}

.blockquote-info {
	margin: 24px 0;
	padding: 4px 24px;
	border-left: 5px solid #ffe564;
	background-color: rgba(255, 229, 100, 0.2);
}

.blockquote-well-done {
	margin: 24px 0;
	padding: 4px 24px;
	border-left: 5px solid #64ffaa;
	background-color: rgba(144, 255, 100, 0.2);
}
